import {
  Button,
  Container,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import UpdatePasswordModal from "./forms/UpdatePasswordModal";
import EditAccountModal from "./forms/EditAccountModal";
import { useHistory } from "react-router-dom";
import {
  getFunctions,
  httpsCallable,
  // connectFunctionsEmulator
} from "firebase/functions";
import fbApp from "../config/fb_config";

export default function UserAccountPage() {
  const loading = useSelector((state) => state.async.loading);
  const currentUser = useSelector((state) => state.user.user);
  const isUser = useSelector((state) => state.user.isUser);
  const history = useHistory();
  const initialUser = isUser ? { ...currentUser } : null;
  const [user, setUser] = useState(initialUser);
  const [openEditAccount, setOpenEditAccount] = useState(false);
  const [openUpdatePassword, setOpenUpdatePassword] = useState(false);

  const functions = getFunctions(fbApp, "northamerica-northeast1");
  // For local testing with emulator
  // connectFunctionsEmulator(functions, "localhost", 5001)

  const sendTestSMS = httpsCallable(functions, "sendTestSMS");
  const sendTestEmail = httpsCallable(functions, "sendTestEmail");

  // For testing PubSub..
  // const sendTestPubSub = httpsCallable(functions, "sendTestPubSub");

  let demo;

  if (currentUser !== null) {
    currentUser.email === "demo@heraldbox.com" ? (demo = true) : (demo = false);
  }

  const cTime = currentUser ? Date.parse(currentUser.createdAt) : null;
  const createTime = new Date(cTime);

  //When user object is populated, apply to component
  useEffect(() => {
    if (isUser) {
      setUser(currentUser);
    }
  }, [currentUser, isUser]);

  //Function to pass to pass to EditAccountModal in order to be able to open and close modal from modal
  const handleEditAccount = (a) => {
    setOpenEditAccount(a);
  };

  //Function to pass to pass to ChangePasswordModal in order to be able to open and close modal from modal
  const handleUpdatePassword = (a) => {
    // console.log("Handle Update Password");
    setOpenUpdatePassword(a);
  };

  const sendSmsMessage = (phoneNumber, e) => {
    e.preventDefault();
    // console.log("sendTestSMS", phoneNumber);
    sendTestSMS({ phone: phoneNumber })
      .then((result) => {
        // Read result of the Cloud Function.
        // /** @type {any} */
        // const data = result.data;
        // console.log("DATA: ", data);
        // console.log("Result from sendtestsms");
      })
      .catch((error) => {
        alert("Error sending text");
      });
  };

  const sendEmailMessage = (phoneNumber, e) => {
    e.preventDefault();
    // console.log("sendTestEmail", phoneNumber);
    sendTestEmail({ phone: phoneNumber })
      .then((result) => {
        // Read result of the Cloud Function.
        // /** @type {any} */
        // const data = result.data;
        // console.log("DATA: ", data);
        // console.log("Result from sendtestemail");
      })
      .catch((error) => {
        alert("Error sending email");
      });
  };

  // const sendPubSubMessage = (phoneNumber, e) => {
  //   e.preventDefault();
  //   console.log("sendTestPubSub", phoneNumber);
  //   sendTestPubSub({
  //     dev_temperature: 25,
  //     dev_humidity: 35,
  //     dev_update_time: "2021-12-08 12:12:12",
  //     dev_power: true,
  //     dev_battery: 99,
  //   })
  //     .then((result) => {
  //       // Read result of the Cloud Function.
  //       // /** @type {any} */
  //       const data = result.data;
  //       console.log("DATA: ", data);
  //       console.log("Result from sendtestemail");
  //     })
  //     .catch((error) => {
  //       alert("Error sending PubSub");
  //     });
  // };

  if (!isUser) {
    return (
      <Container>
        {history.push("/")};{/* {console.log("no user")}; */}
      </Container>
    );
  }

  if (loading && isUser) {
    return (
      <Container>
        <Box sx={{ my: 4 }}>
          <Typography
            style={{
              color: "navy",
            }}
            variant='h6'
            component='h1'
            gutterBottom
          >
            Account Page - Loading...
          </Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Container>
      {openEditAccount && (
        <EditAccountModal
          openEditAccount={openEditAccount}
          pState={handleEditAccount}
        ></EditAccountModal>
      )}
      {openUpdatePassword && (
        <UpdatePasswordModal
          openUpdatePassword={openUpdatePassword}
          pState={handleUpdatePassword}
        ></UpdatePasswordModal>
      )}
      <Box sx={{ my: 4 }}>
        <Grid container spacing={3}>
          <Typography
            style={{
              color: "navy",
            }}
            variant='h6'
            component='h1'
            gutterBottom
          >
            {user ? user.displayName : null} Account Page
          </Typography>
          &nbsp;&nbsp;&nbsp;
          {demo && (
            <Grid>
              <Typography
                style={{
                  color: "orange",
                }}
                // variant='h6'
                component='h1'
                gutterBottom
              >
                Orange buttons are disabled in the Demo
              </Typography>
            </Grid>
          )}
        </Grid>
        <Typography
          style={{
            color: "navy",
          }}
          variant='h8'
          gutterBottom
        >
          {user && (
            <List>
              <ListItem>Name: {user.displayName}</ListItem>
              <ListItem>Email: {user.email}</ListItem>
              <ListItem>Phone Number: {user.phoneNumber}</ListItem>
              <ListItem>Created: {createTime.toLocaleString()}</ListItem>
              {/* <ListItem>Created: {user.createdAt.toLocaleString()}</ListItem> */}
            </List>
          )}
          <Button
            variant='outlined'
            style={demo ? { color: "orange" } : { color: "navy" }}
            onClick={() => setOpenEditAccount(true)}
            disabled={demo}
          >
            Edit Account
          </Button>
          {<br />}
          {<br />}
          <Button
            variant='outlined'
            style={demo ? { color: "orange" } : { color: "navy" }}
            onClick={() => setOpenUpdatePassword(true)}
            disabled={demo}
          >
            Update Password
          </Button>
          {<br />}
          {<br />}
          <Button
            variant='outlined'
            style={demo ? { color: "orange" } : { color: "navy" }}
            onClick={(e) => sendEmailMessage(user.phoneNumber, e)}
            disabled={demo}
          >
            Send Test Email
          </Button>
          {<br />}
          {<br />}
          <Button
            variant='outlined'
            style={demo ? { color: "orange" } : { color: "navy" }}
            onClick={(e) => sendSmsMessage(user.phoneNumber, e)}
            disabled={demo}
          >
            Send Test Text
          </Button>
          {/* {<br />}
          {<br />}
          <Button
            variant='outlined'
            style={{ color: "navy" }}
            onClick={(e) => sendPubSubMessage(user.phoneNumber, e)}
          >
            Send Test PubSub
          </Button> */}
        </Typography>
      </Box>
    </Container>
  );
}
