import { db } from "../config/fb_config";
import {
  doc,
  setDoc,
  getDoc,
  collection,
  Timestamp,
  serverTimestamp,
  updateDoc,
  arrayUnion,
  arrayRemove,
  query,
  where,
  orderBy,
  deleteDoc,
} from "@firebase/firestore";
import { updateUserFireBaseProfile } from "./firebaseService";
import { auth } from "../config/fb_config";

//**Reference to users and devices collections
// const devicesRef = collection(db, "devices");

//Add doc id (snapshot.id) to data. Set time to javascript date
export function dataFromSnapshot(snapshot) {
  if (!snapshot.exists) return undefined;
  const data = snapshot.data();
  for (const prop in data) {
    if (data.hasOwnProperty(prop)) {
      if (data[prop] instanceof Timestamp) {
        data[prop] = data[prop].toDate().toString();
      }
    }
  }
  return {
    ...data,
    id: snapshot.id,
  };
}

// Users Section -----------------------------------------------------------------------------------------------------------------

//Used by Firebase to add user to Firestore
export async function setUserFireStoreData(user, phone) {
  await setDoc(doc(db, "users", user.uid), {
    displayName: user.displayName,
    email: user.email,
    phoneNumber: phone,
    createdAt: serverTimestamp(),
  });
}

//**Used by useFireStoreDoc() to set the user Snapshot document to listen to
export function listenToUserProfileFromFirestore(userId) {
  return doc(db, "users", userId);
}

//**Used by userSlice to get list of users
// export async function fetchUsersFromFirestore() {
//   const users = [];
//   const user = await usersRef.get();
//   user.forEach((doc) => {});
//   user.forEach((doc) => users.push(doc.data()));
//   return users;
// }

//**Used by userSlice to get user
export async function fetchAuthUserFromFirestore(userId) {
  const userRef = doc(userId);
  const docSnap = await getDoc(userRef);
  return docSnap.data();
}

//**Update user db page and also update Firebase profile
export async function updateUserProfile(profile) {
  // const auth = getAuth();
  const user = auth.currentUser;
  const userRef = doc(db, "users", user.uid);
  try {
    await updateDoc(userRef, {
      displayName: profile.displayName,
      phoneNumber: profile.phoneNumber,
    });
    if (user.displayName !== profile.displayName) {
      updateUserFireBaseProfile(profile);
    }
  } catch (error) {
    throw error;
  }
}

//Devices Section--------------------------------------------------------------------------------------------
// Initial settings for new device
const setDevice = (device, owner) => ({
  _id: device.deviceId,
  _name: device.name,
  _owner: device.owner,
  _is_online: false,
  _is_demo: false,
  _max_message_users: 4,
  alert_history_temperature: {},
  alert_history_humidity: {},
  alert_history_power: {},
  alert_history_battery: {},
  alert_history_pir_motion_start: {},
  alert_history_pir_motion_update: {},
  alert_history_pir_motion_end: {},
  set_alerts_active: true,
  sent_temperature_alert: false,
  sent_temperature_alert_time: "",
  sent_humidity_alert: false,
  sent_humidity_alert_time: "",
  sent_low_battery_alert: false,
  sent_low_battery_alert_time: "",
  sent_power_alert: false,
  sent_power_alert_time: "",
  set_high_temperature: "40",
  set_low_temperature: "5",
  set_high_humidity: "80",
  set_low_humidity: "10",
  set_low_battery: "25",
  set_low_battery_active: true,
  set_message_users: [
    {
      name: owner.displayName,
      email: owner.email,
      phone: owner.phoneNumber,
      default: true,
    },
  ],
  set_motion_active: true,
  set_power_active: true,
  set_temperature_active: true,
  set_humidity_active: true,
});

// Check if device is avaialable to be added to user profile
async function checkDev(id) {
  console.log("STARTID:", id);
  const availRef = doc(db, "available_devices", id);
  const docSnap = await getDoc(availRef);
  return docSnap.exists();
}

export async function addDeviceToFirestore(device) {
  const userRef = doc(db, "users", device.owner);
  const userSnap = await getDoc(userRef);
  const userData = userSnap.data();
  console.log("set_high_temperature_active userRef", userData);
  const tf = await checkDev(device.deviceId);
  if (tf) {
    await setDoc(
      doc(db, "devices", device.deviceId),
      setDevice(device, userData)
    );
    await updateDoc(userRef, {
      devices: arrayUnion(device.deviceId),
    });
    await deleteDoc(doc(db, "available_devices", device.deviceId));
  } else {
    console.log("Device Unavailable");
    alert('Device Unavailable');
  }
}

export async function updateDeviceInFirestore(device) {
  // console.log("Update Device: ", device);
  const docRef = doc(db, "devices", device._id);
  await updateDoc(docRef, device);
}

// export async function updateAdminMessageUserInFirestore(device, admin) {
//   // console.log("Update Device: ", device);
//   const docRef = doc(db, "devices", device._id);
//   await updateDoc(docRef, device);
// }

export async function deleteDeviceInFirestore(device, user) {
  const userRef = doc(db, "users", user.id);
  console.log("Document: ", device._id);
  console.log("deleteDeviceInFirestore user: ", user.id);
  console.log("deleteDeviceInFirestore userRef: ", userRef);
  await setDoc(doc(db, "available_devices", device._id), { _id: device._id });
  await deleteDoc(doc(db, "devices", device._id));
  await updateDoc(userRef, {
    devices: arrayRemove(device._id),
  });
  console.log("Document successfully deleted!");
}

export async function addDeviceMessageUser(device) {
  console.log("Add Device message user: ", device);
  const docRef = doc(db, "devices", device._id);
  await updateDoc(docRef, {
    set_message_users: arrayUnion(device),
  }).catch((error) => {
    console.log("ERROR", error);
  });
}

export async function setAlertsActiveInFirestore(device, tf) {
  console.log("Activate Alerts: ", device);
  const docRef = doc(db, "devices", device._id);
  await updateDoc(docRef, device);
}

export async function clearAlertHistoryInFirestore(deviceId, alertHistory) {
  const docRef = doc(db, "devices", deviceId);
  switch (alertHistory) {
    case "temperature":
      await updateDoc(docRef, { alert_history_temperature: {} });
      break;
    case "humidity":
      await updateDoc(docRef, { alert_history_humidity: {} });
      break;
    case "power":
      await updateDoc(docRef, { alert_history_power: {} });
      break;
    case "battery":
      await updateDoc(docRef, { alert_history_battery: {} });
      break;
    case "motion":
      await updateDoc(docRef, {
        alert_history_pir_motion_start: {},
        alert_history_pir_motion_update: {},
        alert_history_pir_motion_end: {},
        dev_pir_motion: false
      });
      break;
    case "all":
      await updateDoc(docRef, {
        alert_history_pir_motion_start: {},
        alert_history_pir_motion_update: {},
        alert_history_pir_motion_end: {},
        dev_pir_motion: false,
        alert_history_temperature: {},
        alert_history_power: {},
        alert_history_battery: {},
      });
      break;
    default:
      console.log("Do nothing");
  }
}

export async function deleteDeviceMessageUser(device) {
  // console.log("delete Device message user: ", name);
  const docRef = doc(db, "devices", device._id);
  await updateDoc(docRef, {
    set_message_users: arrayRemove(device),
  });
}

//**Used by userDeviceSlice to get list of devices
// export async function fetchUserDevicesFromFirestore(userId) {
//   const devices = [];
//   const devs = await devicesRef.get();
//   devs.forEach((doc) => {});
//   devs.forEach((doc) => devices.push(doc.data()));
//   return devices;
// }

//**Used by useFireStoreDoc() to set the document Snapshot to listen to
export async function getDeviceFromFirestore(docId) {
  const docRef = doc(db, "devices", docId);
  const docSnap = await getDoc(docRef);
  return docSnap.data();
}

//**Used by useFireStoreDoc() to set the document Snapshot to listen to
// export async function getUserDeviceNamesFromFirestore(userDoc) {
//   const docRef = doc(db, "users", userDoc);
//   const docSnap = await getDoc(docRef);
//   if (docSnap.exists()) {
//     docSnap.data().devices ? docSnap.data().devices.forEach((device) =>
//       getDeviceFromFirestore(device).then((dev) => {
//         console.log("NNNNNNNNNNNNNNNNNNNNNNNNNNName", dev.name);
//       })
//     ) : console.log('getUserDeviceNamesFromFirestore no devices');

//     return docSnap.data().devices;
//   } else {
//     // doc.data() will be undefined in this case
//     console.log("No such document!");
//     alert("listenToUserDevicesFromFirestore No such document!");
//   }
// }

//**Used by useFireStoreCollection() to set the collection Snapshot to listen to
export function listenToUserDevicesFromFirestore(userId) {
  return query(
    collection(db, "devices"),
    where("_owner", "==", userId),
    orderBy("_name")
  );
}

export function listenToUserDeviceFromFirestore(deviceId) {
  return doc(db, "devices", deviceId);
}

//**Used by useFireStoreCollection() to set the collection Snapshot to listen to
// export async function listenToUserDevicesFromFirestore(userDoc) {
//   const docRef = doc(db, "users", userDoc);
//   const docSnap = await getDoc(docRef);
//   if (docSnap.exists()) {
//     // console.log("Document data:", docSnap.data());
//     return docSnap.data().devices;
//   } else {
//     // doc.data() will be undefined in this case
//     console.log("No such document!");
//     alert("listenToUserDevicesFromFirestore No such document!");
//   }
// }

// export function listenToUserDeviceFromFirestore(deviceId) {
//   return db.collection("devices").doc(deviceId);
// }
