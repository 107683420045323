import { createSlice } from "@reduxjs/toolkit";

export const asyncSlice = createSlice({
  name: "async",
  initialState: {
    loading: false,
    intialized: false,
    error: null,
  },
  reducers: {
    asyncStart: (state) => {
      state.loading = true;
      //console.log("Loading is true from asyncStart");
      state.initialized = false;
      state.error = null;
    },
    asyncFinish: (state) => {
      state.loading = false;
     // console.log("Loading is false from asyncFinish", state.loading);
      state.initialized = true;
      state.error = null;
    },
    asyncError: (state, action) => {
      state.loading = false;
      state.initialized = false;
      state.error = action.payload;
      //console.log("ASYNC ERROR:");
      //console.log(action.payload);
    },
  },
});

export const { asyncStart, asyncFinish, asyncError } = asyncSlice.actions;

export default asyncSlice.reducer;
