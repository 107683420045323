import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import {
  Button,
  Container,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { listenToOneDevice } from "../store/userDeviceSlice";
import AddDeviceModal from "./forms/AddDeviceModal";
import EditDeviceModal from "./forms/EditDeviceModal";
import UserDevicePage from "./UserDevicePage";

const DeviceNavbar = () => {
  const dispatch = useDispatch();
  const userDevices = useSelector((state) => state.device.devices);
  const deviceId = () => {
    if (JSON.parse(sessionStorage.getItem("device"))) {
      return JSON.parse(sessionStorage.getItem("device"))._id;
    } else {
      return userDevices[0]._id;
    }
  };
  const device = useSelector((state) =>
    state.device.devices.find((e) => e._id === deviceId())
  );
  const [openAddDevice, setOpenAddDevice] = useState(false);
  const [useDevice, setUseDevice] = useState(device);
  const [devPage, setOpenDevPage] = useState(false);
  const [openEditDevice, setOpenEditDevice] = useState(false);
  const [alignment, setAlignment] = useState("web");
  const handleEditDevice = (a) => {
    setOpenEditDevice(a);
  };
  const handleAddDevice = (a) => {
    setOpenAddDevice(a);
  };
  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  const selectDevice = (device) => {
    dispatch(listenToOneDevice(device));
    setUseDevice(device);
    sessionStorage.setItem("device", JSON.stringify(device));
  };

  useEffect(() => {
    dispatch(listenToOneDevice(device));
    setUseDevice(device);
  }, [device, dispatch]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      {openAddDevice && (
        <AddDeviceModal
          openAddDevice={openAddDevice}
          pState={handleAddDevice}
        ></AddDeviceModal>
      )}
      {openEditDevice && (
        <EditDeviceModal
          openEditDeviceSettings={openEditDevice}
          pState={handleEditDevice}
        ></EditDeviceModal>
      )}
      <AppBar position='static' color='transparent' elevation={0}>
        <Toolbar>
          <Box sx={{ flexGrow: 1}}>
            <Typography
              variant='h6'
              component='div'
              sx={{ flexGrow: 1 }}
              color='navy'
            >
              Select HeraldBox:
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }}>
            <ToggleButtonGroup
              color='primary'
              value={alignment}
              exclusive
              onChange={handleChange}
              edge='start'
            >
              {userDevices.map((device) => (
                <ToggleButton
                  value={device._name}
                  key={device._name}
                  onClick={() => {
                    selectDevice(device);
                    setOpenDevPage(true);
                  }}
                >
                  {device._name}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Box>
          &nbsp;&nbsp;
          <Grid>
            <Button
              variant='outlined'
              style={{ color: "navy" }}
              size='small'
              onClick={() => setOpenAddDevice(true)}
            >
              Add Device
            </Button>
          </Grid>
        </Toolbar>
      </AppBar>
      {devPage && <UserDevicePage device={useDevice}></UserDevicePage>}
    </Box>
  );
};

export default DeviceNavbar;
