import { Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => (
  <Container>
    <Box sx={{ my: 4 }}>
      <Typography
        style={{
          color: "navy",
        }}
        variant='h4'
        component='h1'
        gutterBottom
      >
        404 - Page Not Found!
        <br />
        <br />
        Return to <Link to='/'>Home Page</Link>
      </Typography>
    </Box>
  </Container>
);

export default NotFound;
