import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { createNewUserWithEmailAndPassword } from "../../functions/firebaseService";

export default function SignUpModal(props) {
  //const { classes } = props;
  const [open, setOpen] = useState(false);
  const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);

  function handleClose() {
    props.pState(false);
    setOpen(false);
  }

  useEffect(() => {
    setOpen(props.openSignUp);
    return () => {
      setOpen({}); // State cleanup
    };
    // eslint-disable-next-line
  }, []);

  const phoneRegExp =
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
      >
        {!isSubmitionCompleted && (
          <React.Fragment>
            <DialogTitle id='form-dialog-title' style={{ color: "navy" }}>
              Sign Up
            </DialogTitle>
            <DialogContent>
              <DialogContentText color='navy'>
                Create an Account
              </DialogContentText>
              <Formik
                initialValues={{
                  email: "",
                  name: "",
                  phone: "",
                  deviceId: "",
                  password: "",
                  confirmPassword: "",
                }}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  values.devices = ['test'];
                  // console.log("Values: ", values);
                  try {
                    createNewUserWithEmailAndPassword(values).then(handleClose());
                    //registerInFirebase(values).then(handleClose());
                    setSubmitionCompleted(true);
                  } catch (error) {
                    window.alert(error);
                  }
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string("Enter your email")
                    .email("Invalid email format")
                    .required("Required"),
                  name: Yup.string("Enter your name")
                    .min(3, "Name should be of minimum 3 characters length")
                    .required("Required"),
                  phone: Yup.string("Enter your phone number")
                    .matches(phoneRegExp, "Phone number is not valid")
                    .required("Required"),
                  deviceId: Yup.string("Enter your device ID number")
                    // .matches('HeraldBox123', "Device number is not valid")
                    .required("Required"),
                  password: Yup.string("Enter your password")
                    .min(8, "Password should be of minimum 8 characters length")
                    .required("Required"),
                  confirmPassword: Yup.string("Confirm your password")
                    .oneOf([Yup.ref("password"), null], "Passwords must match")
                    .required("Required"),
                })}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                  } = props;
                  return (
                    <form onSubmit={handleSubmit}>
                      <Grid container direction={"column"} spacing={0}>
                        <Grid item>
                          <TextField
                            error={errors.name && touched.name}
                            label='name'
                            name='name'
                            type='name'
                            /* className={classes.textField} */
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                              errors.name && touched.name && errors.name
                            }
                            margin='normal'
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            error={errors.email && touched.email}
                            label='email'
                            name='email'
                            tpye='email'
                            //className={classes.textField}
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                              errors.email && touched.email && errors.email
                            }
                            margin='normal'
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            error={errors.phone && touched.phone}
                            label='phone'
                            name='phone'
                            type='phone'
                            //className={classes.textField}
                            value={values.phone}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                              errors.phone && touched.phone && errors.phone
                            }
                            margin='normal'
                          />
                        </Grid>
                        
                        <Grid item>
                          <TextField
                            error={errors.deviceId && touched.deviceId}
                            label='deviceId'
                            name='deviceId'
                            type='deviceId'
                            //className={classes.textField}
                            value={values.deviceId}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                              errors.deviceId && touched.deviceId && errors.deviceId
                            }
                            margin='normal'
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            error={errors.password && touched.password}
                            label='password'
                            name='password'
                            type='password'
                            //className={classes.textField}
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                              errors.password &&
                              touched.password &&
                              errors.password
                            }
                            margin='normal'
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            error={
                              errors.confirmPassword && touched.confirmPassword
                            }
                            label='confirm password'
                            name='confirmPassword'
                            type='password'
                            //className={classes.textField}
                            value={values.confirmPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                              errors.confirmPassword &&
                              touched.confirmPassword &&
                              errors.confirmPassword
                            }
                            margin='normal'
                          />
                        </Grid>
                        <Grid item>
                          <DialogActions>
                            <Button
                              type='button'
                              disabled={isSubmitting}
                              onClick={handleClose}
                            >
                              Cancel
                            </Button>
                            <Button
                              type='button'
                              className='outline'
                              onClick={handleReset}
                              disabled={!dirty || isSubmitting}
                            >
                              Reset
                            </Button>
                            <Button type='submit' disabled={isSubmitting}>
                              Submit
                            </Button>
                          </DialogActions>
                        </Grid>
                      </Grid>
                    </form>
                  );
                }}
              </Formik>
            </DialogContent>
          </React.Fragment>
        )}
        {/* {isSubmitionCompleted && (
          <React.Fragment>
            <DialogTitle id='form-dialog-title'>Thanks!</DialogTitle>
            <DialogContent>
              <DialogContentText>User Created!</DialogContentText>
              <DialogActions>
                <Button type='button' className='outline' onClick={handleClose}>
                  Back to app
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )} */}
      </Dialog>
    </React.Fragment>
  );
}
