import { createSlice } from "@reduxjs/toolkit";

export const authUserSlice = createSlice({
  name: "user",
  initialState: {
    loading: "idle",
    user: null,
    isUser: false,
    error: null,
    initialized: false,
  },
  reducers: {
    listenToAuthUser: (state, action) => {
      state.user = action.payload;
      state.isUser = true;
    },
  },
});

export const { loadingUser, listenToAuthUser } =
  authUserSlice.actions;

export default authUserSlice.reducer;