import { useDispatch } from "react-redux";
import { useEffect } from "react";
//import { asyncStart, asyncError, asyncFinish } from '../async/asyncSlice';
import { dataFromSnapshot } from "./firestoreService";
import { asyncStart, asyncError, asyncFinish } from "../store/asyncSlice";
import { onSnapshot } from "@firebase/firestore";

/*
Custom hook used to query Firestore collection - used to return user device list
 - query is function passed in from firestore query (ie () => listenToDevicesFromFirestore())
 - data is function passed in from Redux dispatch (ie. (devices) => dispatch(listenToDevices(devices))
 - deps is the useEffect dependency passed in from the calling component

Firestore collection is queried, the snapshot returned is mapped to docs. Data function is run with docs 
parameter to update the Redux store. 

Async is dispatched at start and end to update the async status of 
the hook in the Redux store asyncSlice.

The function runs whenever the deps parameter in the originating function changes.
*/
export default function useFirestoreCollection({ query, data, deps }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(asyncStart());
    const unsubscribe = onSnapshot(
      query(),
      (snapshot) => {
        const docs = snapshot.docs.map((doc) => dataFromSnapshot(doc));
        data(docs);
        dispatch(asyncFinish());
      },
      (error) => dispatch(asyncError(error.message))
    );
    return () => {
      unsubscribe();
    };
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps
}
