import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import SignUpModal from "./forms/SignUpModal";
import LogInModal from "./forms/LogInModal";
import { useSelector, useDispatch } from "react-redux";
import { signOutFirebase } from "../functions/firebaseService";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useHistory } from "react-router-dom";
import { Link } from "@mui/material";
import Box from "@mui/material/Box";
import { signOut } from "../store/authSlice";

const Navbar = () => {
  const isAuth = useSelector((state) => state.auth.authenticated);
  const currentUser = useSelector((state) => state.user.user);
  const isUser = useSelector((state) => state.user.isUser);

  const history = useHistory();
  const [openSignUp, setOpenSignUp] = useState(false);
  const [openLogIn, setOpenLogIn] = useState(false);
  const [userName, setUserName] = useState(
    isUser ? currentUser.displayName : null
  );

  const dispatch = useDispatch();

  //Show current user name on Nav Bar
  useEffect(() => {
    if (isUser) {
      setUserName(currentUser.displayName);
      // console.log("navbar UE", currentUser.displayName);
      //setUserName(currentUser[0].displayName);
    }
  }, [currentUser, isUser]);
  //}, [currentUser, auth, isUser]);

  //Function to pass to pass to SignUpModal in order to be able to open and close modal from within modal
  const handleSignUp = (s) => {
    setOpenSignUp(s);
  };

  //Function to pass to pass to LogInModal in order to be able to open and close modal from within modal
  const handleLogIn = (l) => {
    setOpenLogIn(l);
  };

  const handleLogOut = async (l) => {
    // console.log("Logoout....");
    dispatch(signOut());
    await signOutFirebase().then(history.push("/"));
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      {/*Opem modal and pass state (openSignUp) boolean and function (handleSignUp as pState) to set state from SignUpModal */}
      {openSignUp && (
        <SignUpModal
          openSignUp={openSignUp}
          pState={handleSignUp}
        ></SignUpModal>
      )}
      {/*Open modal and pass state (openLogIn) boolean and function (handleLogIn as pState) to set state from LogInModal */}
      {openLogIn && (
        <LogInModal openLogIn={openLogIn} pState={handleLogIn}></LogInModal>
      )}
      <AppBar position='static' color='transparent'>
        <Toolbar>
          <Typography
            variant='h6'
            component='div'
            sx={{ flexGrow: 1 }}
            color='navy'
          >
            <Link underline='none' href='/' to={"/"}>
              HeraldBox
            </Link>
          </Typography>
          {!isAuth && (
            <Button
              style={{ color: "navy" }}
              onClick={() => setOpenLogIn(true)}
            >
              Log In
            </Button>
          )}
          {!isAuth && (
            <Button
              style={{ color: "navy" }}
              onClick={() => setOpenSignUp(true)}
            >
              Sign Up
            </Button>
          )}
          {isAuth && (
            <PopupState variant='popover' popupId='demo-popup-menu'>
              {(popupState) => (
                <React.Fragment>
                  <Button
                    style={{ color: "navy" }}
                    {...bindTrigger(popupState)}
                  >
                    {userName}
                  </Button>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem
                      style={{ color: "navy" }}
                      onClick={() => {
                        history.push("/useraccountpage");
                        popupState.close();
                      }}
                    >
                      My Account
                    </MenuItem>
                    <MenuItem
                      style={{ color: "navy" }}
                      onClick={() => {
                        history.push("/devicenavbar");
                        // console.log('Devices Page Selected');
                        popupState.close();
                      }}
                    >
                      My Devices
                    </MenuItem>
                    <MenuItem
                      style={{ color: "navy" }}
                      onClick={() => {
                        handleLogOut();
                        popupState.close();
                      }}
                    >
                      Logout
                    </MenuItem>
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};
export default Navbar;