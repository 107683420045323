import { Button, Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import { sendEmailVerification } from "firebase/auth";
import { useState } from "react";
import { auth } from "../config/fb_config";
//import { testEmail } from "../functions/sendGridTest";

export default function Home() {
  const user = useSelector((state) => state.auth.currentUser);
  // const auth = getAuth();
  const verified = user.emailVerified;
  const [verificationMessage, setVerificationMessage] = useState(null);

  const sendVerificationLink = () => {
    sendEmailVerification(auth.currentUser).then(() => {
      // console.log("Email verification sent");
      setVerificationMessage(
        "Verification link sent. Please reload this page after activation."
      );
    });
  };

  if (!verified && user.uid !== null) {
    return (
      <Container>
        <Box sx={{ my: 4 }}>
          <Typography
            style={{
              color: "navy",
            }}
            variant='h4'
            component='h1'
            gutterBottom
          >
            {`HB2 - ${user.email} - Account Not Activated. `}
            <br />
            Please use the link you were emailed to activate your account.
            <br />
            Reload this page after activation.
          </Typography>
          <Button
            variant='outlined'
            style={{ color: "navy" }}
            onClick={() => sendVerificationLink()}
          >
            Resend Link
          </Button>
          <Typography
            style={{
              color: "navy",
            }}
            variant='h4'
            component='h1'
            gutterBottom
          >
            {verificationMessage}
          </Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Container>
      <Box sx={{ my: 4 }}>
        <Typography
          style={{
            color: "navy",
          }}
          variant='h4'
          component='h1'
          gutterBottom
        >
          HeraldBox Home {user.uid !== null ? `- Welcome ${user.displayName}` : null}
          <br />
        </Typography>
      </Box>
    </Container>
  );
}
