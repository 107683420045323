import Typography from "@material-ui/core/Typography";
import { Redirect } from "react-router-dom";
import { List, ListItem } from "@material-ui/core";
import { Button, Container, Radio, Switch } from "@mui/material";
import { useSelector } from "react-redux";
import { useState } from "react";
import ManageSettingsModal from "../forms/ManageSettingsModal";
import { clearAlertHistoryInFirestore, updateDeviceInFirestore } from "../../functions/firestoreService";

export default function AlertSettings() {
  const [openManageSettings, setOpenManageSettings] = useState(false);
  const device = useSelector((state) => state.device.device);

  const handleManageSettings = (a) => {
    setOpenManageSettings(a);
  };

  const handleAlerts = (tf) => {
    const alertState = {
      _id: device._id,
      set_alerts_active: tf,
    };
    if (!tf) {
      clearAlertHistoryInFirestore(device._id, 'all');
    }
    updateDeviceInFirestore(alertState);
  };

  const handleTemperature = (event) => {
    const alertState = {
      _id: device._id,
      set_temperature_active: event.target.checked,
    };
    if (!event.target.checked) {
      clearAlertHistoryInFirestore(device._id, 'temperature');
    }
    updateDeviceInFirestore(alertState);
  };
  
  const handleHumidity = (event) => {
    const alertState = {
      _id: device._id,
      set_humidity_active: event.target.checked,
    };
    if (!event.target.checked) {
      clearAlertHistoryInFirestore(device._id, 'humidity');
    }
    updateDeviceInFirestore(alertState);
  };

  const handleLowBattery = (event) => {
    const alertState = {
      _id: device._id,
      set_low_battery_active: event.target.checked,
    };
    if (!event.target.checked) {
      clearAlertHistoryInFirestore(device._id, 'battery');
    }
    updateDeviceInFirestore(alertState);
  };

  const handlePower = (event) => {
    const alertState = {
      _id: device._id,
      set_power_active: event.target.checked,
    };
    if (!event.target.checked) {
      clearAlertHistoryInFirestore(device._id, 'power');
    }
    updateDeviceInFirestore(alertState);
  };

  const handleMotion = (event) => {
    const alertState = {
      _id: device._id,
      set_motion_active: event.target.checked,
    };
    if (!event.target.checked) {
      clearAlertHistoryInFirestore(device._id, 'motion');
    }
    updateDeviceInFirestore(alertState);
  };

  if (device) {
    return (
      <Container>
        {openManageSettings && (
          <ManageSettingsModal
            openManageSettings={openManageSettings}
            pState={handleManageSettings}
          ></ManageSettingsModal>
        )}
        <Typography
          style={{
            color: "navy",
          }}
          variant='h6'
          align='center'
        >
          Alert Settings
          {/* {device._name} Settings */}
        </Typography>
        <Typography
          component='span'
          variant='body2'
          style={{
            color: "navy",
          }}
        >
          <List>
            <ListItem>
              Alerts: Off
              <Radio
                checked={!device.set_alerts_active}
                size='small'
                onChange={() => handleAlerts(false)}
                // onChange={(e) => {handleHighTemp(e); handleLowTemp(e); handleLowBattery(e); handlePower(e); handleMotion(e) }}
                name='allAlertsOff'
              />
              On
              <Radio
                checked={device.set_alerts_active}
                size='small'
                onChange={() => handleAlerts(true)}
                // onChange={(e) => {handleHighTemp(e); handleLowTemp(e); handleLowBattery(e); handlePower(e); handleMotion(e) }}
                name='allAlertsOn'
              />
            </ListItem>
            {/* <ListItem>
              High Temperature Alert: {device.set_high_temperature}
              <Switch
                checked={device.set_high_temperature_active}
                size='small'
                onChange={(e) => handleHighTemp(e)}
                name='highTemp'
                disabled={!device.set_alerts_active}
              />
            </ListItem> */}
            <ListItem>
              Temperature Alert:
              <br />
              Low: &nbsp;
              {device.set_low_temperature}
              &nbsp;&nbsp;&nbsp; High: &nbsp;
              {device.set_high_temperature}
              <Switch
                checked={device.set_temperature_active}
                size='small'
                onChange={(e) => handleTemperature(e)}
                name='highTemp'
                disabled={!device.set_alerts_active}
              />
            </ListItem>
            <ListItem>
              Humiditiy Alert:
              <br />
              Low: &nbsp;
              {device.set_low_humidity}
              &nbsp;&nbsp;&nbsp; High: &nbsp;
              {device.set_high_humidity}
              <Switch
                checked={device.set_humidity_active}
                size='small'
                onChange={(e) => handleHumidity(e)}
                name='highTemp'
                disabled={!device.set_alerts_active}
              />
            </ListItem>
             <ListItem>
              Lost Power Alert:
              <Switch
                checked={device.set_power_active}
                size='small'
                onChange={(e) => handlePower(e)}
                name='noPower'
                disabled={!device.set_alerts_active}
              />
            </ListItem>
            <ListItem>
              Low Battery Alert: {device.set_low_battery}%
              <Switch
                checked={device.set_low_battery_active}
                size='small'
                onChange={(e) => handleLowBattery(e)}
                name='lowBattery'
                disabled={!device.set_alerts_active}
              />
            </ListItem>
            <ListItem>
              Motion Alert:
              <Switch
                checked={device.set_motion_active}
                size='small'
                onChange={(e) => handleMotion(e)}
                name='motion'
                // disabled
                disabled={!device.set_alerts_active}
              />
            </ListItem>
            <ListItem>
              <Button
                variant='outlined'
                style={{ color: "navy" }}
                size='small'
                // onClick={() => console.log("Add Message User Clicked")}
                onClick={() => setOpenManageSettings(true)}
              >
                Manage Settings
              </Button>
            </ListItem>
          </List>
        </Typography>
      </Container>
    );
  } else {
    return <Redirect to='/userdevicepage' />;
  }
}
