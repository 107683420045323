import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator, } from "@firebase/firestore";
import { getAuth, connectAuthEmulator } from "@firebase/auth";

// Your web app's Firebase configuration
const fbApp = initializeApp({
  apiKey: "AIzaSyA_PZ7PlZ84WjHuvqaot6m_bZZyqkBEk-8",
  authDomain: "hb-2-18a67.firebaseapp.com",
  projectId: "hb-2-18a67",
  storageBucket: "hb-2-18a67.appspot.com",
  messagingSenderId: "109329265109",
  appId: "1:109329265109:web:2101e91716facc8d8e8d5c",
  measurementId: "G-SE3TY0BXEH",
});

export const db = getFirestore();
export const auth = getAuth(fbApp);

// eslint-disable-next-line no-restricted-globals
if (location.hostname === "localhost") {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  connectAuthEmulator(auth, "http://localhost:9099");
}

// eslint-disable-next-line no-restricted-globals
if (location.hostname === "localhost") {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  connectFirestoreEmulator(db, "localhost", 8080);
}

export default fbApp;
