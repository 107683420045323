import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  setPasswordEmail,
  signInWithEmail,
} from "../../functions/firebaseService";
import { useSelector } from "react-redux";

export default function LogInModal(props) {
  //const { classes } = props;
  const [open, setOpen] = useState(false);
  const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
  const auth = useSelector((state) => state.auth.authenticated);
  const [openResetPasswordEmail, setOpenResetPasswordEmail] = useState(false);
  const [title, setTitle] = useState("Log In");

  //Function to pass to pass to ChangePasswordModal in order to be able to open and close modal from modal
  // const handleResetPasswordEmail = (a) => {
  //   console.log("Handle Update Password");
  //   setOpenResetPasswordEmail(a);
  // };

  //props used to send closed modal state to NavBar
  function handleClose() {
    props.pState(false);
    setOpen(false);
  }

  useEffect(() => {
    setOpen(props.openLogIn);
    return () => {
      setOpen({}); // State cleanup
    };
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
      >
        {!isSubmitionCompleted && (
          <React.Fragment>
            <DialogTitle id='form-dialog-title' style={{ color: "navy" }}>
              {title}
            </DialogTitle>
            <DialogContent>
              {/* <DialogContentText color='navy'>Create an Account</DialogContentText> */}
              <Formik
                initialValues={{
                  email: "",
                  password: "",
                }}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  // console.log("Values: ", values);
                  try {
                    !openResetPasswordEmail && signInWithEmail(values);
                    openResetPasswordEmail && setPasswordEmail(values);
                    setSubmitionCompleted(true);
                    handleClose();
                  } catch (error) {
                    alert(error.message);
                  }
                  //setSubmitionCompleted(true);
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string("Enter your email")
                    .email("Invalid email format")
                    .required("Required"),
                  password: Yup.string("Enter your password").min(
                    8,
                    "Password should be of minimum 8 characters length"
                  ),
                  // .required("Required"),
                })}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                  } = props;
                  return (
                    <form onSubmit={handleSubmit}>
                      <Grid container direction={"column"} spacing={0}>
                        <Grid
                          item
                          container
                          justifyContent='center'
                          alignItems='center'
                          direction='column'
                        >
                          <TextField
                            error={errors.email && touched.email}
                            label='email'
                            name='email'
                            tpye='email'
                            //className={classes.textField}
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                              errors.email && touched.email && errors.email
                            }
                            margin='normal'
                          />
                        </Grid>
                        {!openResetPasswordEmail && (
                          <Grid
                            item
                            container
                            justifyContent='center'
                            alignItems='center'
                            direction='column'
                          >
                            <TextField
                              error={errors.password && touched.password}
                              label='password'
                              name='password'
                              type='password'
                              //className={classes.textField}
                              value={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              helperText={
                                errors.password &&
                                touched.password &&
                                errors.password
                              }
                              margin='normal'
                            />
                          </Grid>
                        )}
                        <Grid
                          item
                          container
                          justifyContent='center'
                          alignItems='center'
                          direction='column'
                        >
                          <DialogActions>
                            <Button
                              type='button'
                              disabled={isSubmitting}
                              onClick={handleClose}
                            >
                              Cancel
                            </Button>
                            <Button
                              type='button'
                              className='outline'
                              onClick={handleReset}
                              disabled={!dirty || isSubmitting}
                            >
                              Reset
                            </Button>
                            <Button type='submit' disabled={isSubmitting}>
                              Submit
                            </Button>
                          </DialogActions>
                          <Grid
                            item
                            container
                            justifyContent='center'
                            alignItems='center'
                            direction='column'
                          >
                            <Button
                              variant='body2'
                              style={{ color: "navy" }}
                              disabled={isSubmitting}
                              onClick={() => {
                                setOpenResetPasswordEmail(true);
                                setTitle("Email password reset link.");
                                console.info(
                                  "setOpenResetPasswordEmail Link Clicked",
                                  openResetPasswordEmail
                                );
                              }}
                            >
                              {!openResetPasswordEmail && "Forgot Password?"}
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </form>
                  );
                }}
              </Formik>
            </DialogContent>
          </React.Fragment>
        )}
        {isSubmitionCompleted && auth && (
          <React.Fragment>
            <DialogTitle id='form-dialog-title'>Welcome!</DialogTitle>
            <DialogContent>
              <DialogActions>
                <Button type='button' className='outline' onClick={handleClose}>
                  Go to app
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )}
        {isSubmitionCompleted && !auth && (
          <React.Fragment>
            <DialogTitle id='form-dialog-title'>Login Failed</DialogTitle>
            <DialogContent>
              <DialogActions>
                <Button type='button' className='outline' onClick={handleClose}>
                  Back to app
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )}
      </Dialog>
    </React.Fragment>
  );
}
