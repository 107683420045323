import Typography from "@material-ui/core/Typography";
import { Redirect } from "react-router-dom";
import { List } from "@material-ui/core";
import { Alert, Button, Container } from "@mui/material";
import { useSelector } from "react-redux";
import { useState } from "react";
import ManageRecipientsModal from "../forms/ManageRecipientsModal";
import ManageOneRecipientModal from "../forms/ManageOneRecipientModal";

const flexContainer = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  padding: 0,
  alignItems: "flex-start",
};

export default function AlertRecipients() {
  const [openManageRecipients, setOpenManageRecipients] = useState(false);
  const [openManageOneRecipient, setOpenManageOneRecipient] = useState(false);
  const [currentRecipient, setCurrentRecipient] = useState();
  const [alertState, setAlertState] = useState(false);
  const device = useSelector((state) => state.device.device);
  const handleManageRecipients = (a) => {
    setOpenManageRecipients(a);
  };
  const handleManageOneRecipient = (a) => {
    setOpenManageOneRecipient(a);
  };

  let checkList;

  device
    ? (checkList = device.set_message_users.length < device._max_message_users)
    : (checkList = null);
  // console.log('checklist', checkList)

  const editList = () => {
    checkList
      ? setOpenManageRecipients(true)
      : console.log("Maximum four recipients allowed");
    checkList ? setOpenManageRecipients(true) : setAlertState(true);
  };

  if (device) {
    return (
      <Container>
        {openManageRecipients && (
          <ManageRecipientsModal
            openManageRecipients={openManageRecipients}
            pState={handleManageRecipients}
          ></ManageRecipientsModal>
        )}
        {openManageOneRecipient && (
          <ManageOneRecipientModal
            openManageOneRecipient={openManageOneRecipient}
            pState={handleManageOneRecipient}
            user={currentRecipient}
          ></ManageOneRecipientModal>
        )}
        {alertState && (
          <Alert
            severity='info'
            onClose={() => {
              setAlertState(false);
            }}
          >
            Maximum four recipients allowed
          </Alert>
        )}
        <Typography
          style={{
            color: "navy",
          }}
          variant='h6'
          align='center'
        >
          Alert Recipients
        </Typography>
        <Typography
          component='span'
          variant='body2'
          style={{
            color: "navy",
          }}
        >
          {device.set_message_users.map((user) => (
            <List style={flexContainer} key={user.name}>
              <Button
                variant='text'
                key={user.name}
                onClick={() => {
                  // setCurrentName(user.name);
                  console.log("owner:", user);
                  setCurrentRecipient({
                    name: user.name,
                    phone: user.phone,
                    email: user.email,
                    _id: device._id,
                  });
                  if (!user.default){
                  setOpenManageOneRecipient(true);
                  } else {
                    alert("Owner can only be edited from owner Account page");
                  }
                }}
                // onClick={() => console.log("messages button clicked")}
              >
                {user.name}
                {user.default ? ' (Owner)' : null}
              </Button>
              
              {/* <br /> */}
              {user.phone}
              <br />
              {user.email}
              <br />
              <br />
            </List>
          ))}
          <Button
            variant='outlined'
            style={{ color: "navy" }}
            size='small'
            // onClick={() => console.log("Add Message User Clicked")}
            onClick={() => editList()}
            // onClick={() => setOpenManageRecipients(true)}
          >
            Add Recipient
          </Button>
        </Typography>
      </Container>
    );
  } else {
    return <Redirect to='/userdevicepage' />;
  }
}
