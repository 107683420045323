import { createSlice } from "@reduxjs/toolkit";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../config/fb_config";


//dispatch(verifyAuth()) in App() to get authentication status when app loads
export function verifyAuth() {
  // const auth = getAuth();
  return function (dispatch) { 
    return onAuthStateChanged(auth, (user) => {
    //return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        const payload = {
          currentUser: {
            email: user.email,
            uid: user.uid,
            displayName: user.displayName,
            emailVerified: user.emailVerified
            //providerId: user.providerData[0].providerId,
          },
        };
        // console.log('auth slice user:', user)
        dispatch(signIn(payload));
      } else {
        dispatch(signOut());
      }
    });
  };
}

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    loading: "idle",
    currentUser: {
      email: null,
      uid: null,
      displayName: null,
      emailVerified: false
    },
    authenticated: false,
    error: null,
  },
  reducers: {
    signIn: (state, action) => {
      //console.log('before signin'/* , current(state) */)
      state.authenticated = true;
      state.currentUser = action.payload.currentUser;
      // console.log("auth slice current user", action.payload.currentUser);
      // console.log('email', action.payload.currentUser.email)
    },
    signOut: (state) => {
      //console.log('before', current(state))
      //state.authenticated =  {authenticated: false};
      state.authenticated = false;
      state.currentUser.email = null;
      state.currentUser.displayName = null;
      state.currentUser.uid = null;
      state.currentUser.emailVerified = false;
      // console.log('after', current(state))
    },
    fbError: (state, action) => {
      //console.log('before', current(state))
      state.error = action.payload;
      //console.log('after', current(state))
    },
  },
  extraReducers: {},
});

export const { signIn, signOut, fbError } = authSlice.actions;

export default authSlice.reducer;
