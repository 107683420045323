import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { updateDeviceInFirestore } from "../../functions/firestoreService";

export default function ManageSettingsModal(props) {
  const [open, setOpen] = useState(false);
  const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
  const device = useSelector((state) => state.device.device);
  // console.log("editdevicemodal", device);

  // Rename form keys for submission to Firestore
  function renameKey(obj, oldKey, newKey) {
    obj[newKey] = obj[oldKey];
    delete obj[oldKey];
  }

  function handleClose() {
    props.pState(false);
    setOpen(false);
  }

  useEffect(() => {
    setOpen(props.openManageSettings);
    return () => {
      setOpen({}); // Cleanup state
    };
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
      >
        {!isSubmitionCompleted && (
          <React.Fragment>
            <DialogTitle id='form-dialog-title' style={{ color: "navy" }}>
              {`Manage ${device._name} Settings`}
            </DialogTitle>
            <DialogContent>
              <Formik
                initialValues={{
                  _id: device._id,
                  highTemperature: device.set_high_temperature,
                  lowTemperature: device.set_low_temperature,
                  highHumidity: device.set_high_humidity,
                  lowHumidity: device.set_low_humidity,
                  lowBattery: device.set_low_battery,
                }}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  renameKey(values, "highTemperature", "set_high_temperature");
                  renameKey(values, "lowTemperature", "set_low_temperature");
                  renameKey(values, "highHumidity", "set_high_humidity");
                  renameKey(values, "lowHumidity", "set_low_humidity");
                  renameKey(values, "lowBattery", "set_low_battery");
                  // console.log('ManageSettingsModal', values)
                  try {
                    updateDeviceInFirestore(values).then(handleClose());
                    setSubmitionCompleted(true);
                  } catch (error) {
                    window.alert(error);
                  }
                }}
                validationSchema={Yup.object().shape({
                  highTemperature: Yup.number(
                    "Enter high temperature alert level."
                  )
                    .typeError("You must use a number.")
                    .min(0, "Minimum value of zero")
                    .moreThan(
                      Yup.ref("lowTemperature"),
                      "Must be more than Low Temperature"
                    )
                    .required("This value is required"),
                  lowTemperature: Yup.number(
                    "Enter low temperature alert level."
                  )
                    .typeError("You must use a number.")
                    .required("This value is required"),
                  highHumidity: Yup.number(
                    "Enter high humidity alert level."
                  )
                    .typeError("You must use a number.")
                    .min(0, "Minimum value of zero")
                    .moreThan(
                      Yup.ref("lowHumidity"),
                      "Must be more than Low Humidity"
                    )
                    .required("This value is required"),
                  lowHumidity: Yup.number(
                    "Enter low humidity alert level."
                  )
                    .typeError("You must use a number.")
                    .required("This value is required"),
                  lowBattery: Yup.number("Enter low battery alert level")
                    .min(10, "Minimum value of ten percent")
                    .typeError("You must use a number.")
                    .required("This value is required"),
                })}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                  } = props;
                  return (
                    <form onSubmit={handleSubmit}>
                      <Grid container direction={"column"} spacing={0}>
                        <Grid item>
                          <TextField
                            error={
                              errors.highTemperature && touched.highTemperature
                            }
                            label='highTemperature'
                            name='highTemperature'
                            type='highTemperature'
                            //className={classes.textField}
                            value={values.highTemperature}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                              errors.highTemperature &&
                              touched.highTemperature &&
                              errors.highTemperature
                            }
                            margin='normal'
                            autoComplete='off'
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            error={
                              errors.lowTemperature && touched.lowTemperature
                            }
                            label='lowTemperature'
                            name='lowTemperature'
                            type='lowTemperature'
                            //className={classes.textField}
                            value={values.lowTemperature}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                              errors.lowTemperature &&
                              touched.lowTemperature &&
                              errors.lowTemperature
                            }
                            margin='normal'
                            autoComplete='off'
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            error={
                              errors.highHumidity && touched.highHumidity
                            }
                            label='highHumidity'
                            name='highHumidity'
                            type='highHumidity'
                            //className={classes.textField}
                            value={values.highHumidity}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                              errors.highHumidity &&
                              touched.highHumiditye &&
                              errors.highHumidity
                            }
                            margin='normal'
                            autoComplete='off'
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            error={
                              errors.lowHumidity && touched.lowHumidity
                            }
                            label='lowHumidity'
                            name='lowHumidity'
                            type='lowHumidity'
                            //className={classes.textField}
                            value={values.lowHumidity}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                              errors.lowHumidity &&
                              touched.lowHumidity &&
                              errors.lowHumidity
                            }
                            margin='normal'
                            autoComplete='off'
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            error={errors.lowBattery && touched.lowBattery}
                            label='lowBattery'
                            name='lowBattery'
                            type='lowBattery'
                            //className={classes.textField}
                            value={values.lowBattery}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                              errors.lowBattery &&
                              touched.lowBattery &&
                              errors.lowBattery
                            }
                            margin='normal'
                            autoComplete='off'
                          />
                        </Grid>
                        <Grid item>
                          <DialogActions>
                            <Button
                              type='button'
                              disabled={isSubmitting}
                              onClick={handleClose}
                            >
                              Cancel
                            </Button>
                            <Button
                              type='button'
                              className='outline'
                              onClick={handleReset}
                              disabled={!dirty || isSubmitting}
                            >
                              Reset
                            </Button>
                            <Button type='submit' disabled={isSubmitting}>
                              Submit
                            </Button>
                          </DialogActions>
                        </Grid>
                      </Grid>
                    </form>
                  );
                }}
              </Formik>
            </DialogContent>
          </React.Fragment>
        )}
        {isSubmitionCompleted && (
          <React.Fragment>
            <DialogTitle id='form-dialog-title'>Thanks!</DialogTitle>
            <DialogContent>
              <DialogContentText>Device Updated!</DialogContentText>
              <DialogActions>
                <Button type='button' className='outline' onClick={handleClose}>
                  Back to app
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )}
      </Dialog>
    </React.Fragment>
  );
}
