import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
//import { useSelector } from "react-redux";
import { updateUserPassword } from "../../functions/firebaseService";

export default function ChangePasswordModal(props) {
  //const { classes } = props;
  const [open, setOpen] = useState(false);
  const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);

  function handleClose() {
    props.pState(false);
    setOpen(false);
  }

  useEffect(() => {
    setOpen(props.openUpdatePassword);
    return () => {
        setOpen({}); // Cleanup state
      };
    // eslint-disable-next-line 
  }, []);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
      >
        {!isSubmitionCompleted && (
          <React.Fragment>
            <DialogTitle id='form-dialog-title' style={{ color: "navy" }}>Update Password</DialogTitle>
            <DialogContent>
              <Formik
                initialValues={{
                  currentPassword: '',
                  password: '',
                  confirmPassword: '', 
                }}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  // console.log("Values: ", values);
                  try {
                    updateUserPassword(values)
                    .then(handleClose());
                    setSubmitionCompleted(true);
                  } catch(error) {
                    window.alert(error);
                  }
                }}
                validationSchema={Yup.object().shape({
                  currentPassword: Yup.string("Enter your current password")
                    /* .min(8, "Password should be of minimum 8 characters length")
                    .required("Required") */,
                  password: Yup.string("Enter your password")
                    .min(8, "Password should be of minimum 8 characters length")
                    .required("Required"),
                  confirmPassword: Yup.string("Confirm your password")
                    .oneOf([Yup.ref("password"), null], "Passwords must match")
                    .required("Required"),
                })}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                  } = props;
                  return (
                    <form onSubmit={handleSubmit}>
                      <Grid container direction={"column"} spacing={0 }> 
                        <Grid item>
                          <TextField
                            error={errors.currentPassword && touched.currentPassword}
                            label='current password'
                            name='currentPassword'
                            type='password'
                            value={values.currentPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                              errors.currentPassword &&
                              touched.currentPassword &&
                              errors.currentPassword
                            }
                            margin='normal'
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            error={errors.password && touched.password}
                            label='new password'
                            name='password'
                            type='password'
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                              errors.password &&
                              touched.password &&
                              errors.password
                            }
                            margin='normal'
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            error={
                              errors.confirmPassword && touched.confirmPassword
                            }
                            label='confirm new password'
                            name='confirmPassword'
                            type='password'
                            //className={classes.textField}
                            value={values.confirmPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                              errors.confirmPassword &&
                              touched.confirmPassword &&
                              errors.confirmPassword
                            }
                            margin='normal'
                          />
                        </Grid>
                        <Grid item>
                          <DialogActions>
                            <Button
                              type='button'
                              disabled={isSubmitting}
                              onClick={handleClose}
                            >
                              Cancel
                            </Button>
                            <Button
                              type='button'
                              className='outline'
                              onClick={handleReset}
                              disabled={!dirty || isSubmitting}
                            >
                              Reset
                            </Button>
                            <Button type='submit' disabled={isSubmitting}>
                              Submit
                            </Button>
                          </DialogActions>
                        </Grid>
                      </Grid>
                    </form>
                  );
                }}
              </Formik>
            </DialogContent>
          </React.Fragment>
        )}
        {isSubmitionCompleted && (
          <React.Fragment>
            <DialogTitle id='form-dialog-title'>Thanks!</DialogTitle>
            <DialogContent>
              <DialogContentText>Password Updated!</DialogContentText>
              <DialogActions>
                <Button type='button' className='outline' onClick={handleClose}>
                  Back to app
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )}
      </Dialog>
    </React.Fragment>
  );
}
