import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  Alert,
} from "@mui/material";
import { deleteDeviceMessageUser } from "../../functions/firestoreService";
import { useConfirm } from "material-ui-confirm";

export default function ManageOneRecipientModal(props) {
  const [open, setOpen] = useState(false);
  const confirm = useConfirm();

  function handleClose() {
    props.pState(false);
    setOpen(false);
  }

  // console.log("recipient modal", props.user);

  const deleteRecipient = () => {
    confirm({
      description: `Are you sure you want to delete ${props.user.name}`,
    })
      .then(() => {
        deleteDeviceMessageUser(props.user);
      })
      .then(() => {
        handleClose();
      })
      .catch(() => {
        // console.log("Delete Cancelled");
        <Alert severity='error'>Delete default user not allowed!</Alert>;
      });
  };

  useEffect(() => {
    setOpen(props.openManageOneRecipient);
    return () => {
      setOpen({}); // Cleanup state
    };
    // eslint-disable-next-line
  }, []);

  const flexContainer = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    padding: 0,
    alignItems: "center",
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
      >
        <React.Fragment>
          <List style={flexContainer}>
            <ListItem alignItems='center'>
              <DialogTitle id='form-dialog-title' style={{ color: "navy" }} alignItems='center'>
                Delete
              </DialogTitle>
            </ListItem>
            <ListItem alignItems='center'>
              <Button
                variant='outlined'
                style={{ color: "navy" }}
                size='small'
                onClick={() => {
                  // console.log("Delete User Clicked");
                  deleteRecipient();
                  // handleClose();
                }}
              >
                {`Delete ${props.user.name}`}
              </Button>
            </ListItem>
          </List>
        </React.Fragment>
      </Dialog>
    </React.Fragment>
  );
}
