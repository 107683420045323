import { configureStore } from "@reduxjs/toolkit";
import userDeviceReducer from "./userDeviceSlice";
import asyncReducer from "./asyncSlice";
import userReducer from "./authUserSlice";
import authReducer from "./authSlice";

const reducer = {
  device: userDeviceReducer,
  async: asyncReducer,
  user: userReducer,
  auth: authReducer
};

export default configureStore({
  reducer,
});