import Typography from "@material-ui/core/Typography";
import { Redirect } from "react-router-dom";
import { List, ListItem } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Button, Container } from "@mui/material";
import {
  getFunctions,
  httpsCallable,
  // connectFunctionsEmulator,
} from "@firebase/functions";
import fbApp from "../../config/fb_config";

export default function CurrentState() {
  const device = useSelector((state) => state.device.device);

  const functions = getFunctions(fbApp, "northamerica-northeast1");
  // For local testing with emulator
  // connectFunctionsEmulator(functions, "localhost", 5001);

  const updateDeviceState = httpsCallable(functions, "updateDeviceState");

  // function toDateTime(secs) {
  //   const t = new Date(secs);
  //   // console.log("Date: ", t.toLocaleString());
  //   return t.toLocaleString();
  // }

  const requestDeviceUpdate = (deviceId, e) => {
    e.preventDefault();
    // console.log("getDeviceUpdate", deviceId);
    updateDeviceState({ deviceId: deviceId })
      .then((result) => {
        // Read result of the Cloud Function.
        // /** @type {any} */
        // const data = result.data;
        // console.log("DATA: ", data);
        // console.log("Result from sendtestsms");
      })
      .catch((error) => {
        alert("Error updating", error);
        // console.log(error);
      });
    // alert("Updating");
  };

  const updateTime = device ? Date.parse(device.dev_update_time) : null;
  const startupTime = device ? Date.parse(device.dev_startup_time) : null;
  // console.log('updateTime1', updateTime)
  // console.log('updateTime1', toDateTime(updateTime))
  // console.log('startupTime1', startupTime)
  // console.log('startupTime2', toDateTime(startupTime))
  const udTime = new Date(updateTime);
  const suTime = new Date(startupTime);


  if (device) {
    return (
      <Container>
        {device._is_online && (
          <Typography
            style={{
              color: "navy",
            }}
            variant='h6'
            align='center'
          >
            Current State
          </Typography>
        )}
        {!device._is_online && (
          <Typography
            style={{
              color: "red",
            }}
            variant='h6'
            align='center'
          >
            Current State: Offline
          </Typography>
        )}
        <Typography
          component='span'
          variant='body2'
          style={{
            color: "navy",
          }}
        >
          <List>
            <ListItem
              style={
                parseInt(device.dev_temperature) <
                  parseInt(device.set_low_temperature) ||
                parseInt(device.dev_temperature) >
                  parseInt(device.set_high_temperature)
                  ? { color: "red" }
                  : { color: "green" }
              }
            >
              Temperature: {device.dev_temperature}
            </ListItem>
            <ListItem
              style={
                parseInt(device.dev_humidity) <
                  parseInt(device.set_low_humidity) ||
                parseInt(device.dev_humidity) >
                  parseInt(device.set_high_hunmidity)
                  ? { color: "red" }
                  : { color: "green" }
              }
            >
              Humidity: {device.dev_humidity}
            </ListItem>
            {/* <ListItem style={{ color: "green" }}>
              Humidity: {device.dev_humidity}
            </ListItem> */}
            <ListItem
              style={
                device.dev_power === 1 ? { color: "green" } : { color: "red" }
              }
            >
              Power: {device.dev_power === 1 ? "true" : "false"}
            </ListItem>
            <ListItem
              style={
                parseInt(device.dev_battery) < parseInt(device.set_low_battery)
                  ? { color: "red" }
                  : { color: "green" }
              }
            >
              Battery %: {device.dev_battery}
            </ListItem>
            <ListItem
              style={
                !device.dev_pir_motion ? { color: "green" } : { color: "red" }
              }
            >
              PIR Motion: {device.dev_pir_motion === true ? "true" : "false"}
            </ListItem>
            <ListItem>
              Last Update: <br /> {udTime.toLocaleString()}
              {/* Last Update: <br /> {device.dev_update_time.toLocaleString()} */}
            </ListItem>
            <ListItem>
              Last Startup: <br /> {suTime.toLocaleString()}
            </ListItem>
            <ListItem>
              <Button
                variant='outlined'
                style={{ color: "navy" }}
                size='small'
                // onClick={() => console.log("Add Message User Clicked")}
                onClick={(e) => requestDeviceUpdate(device._id, e)}
              >
                Get Update
              </Button>
            </ListItem>
          </List>
        </Typography>
      </Container>
    );
  } else {
    return <Redirect to='/userdevicepage' />;
  }
}
