import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { updateUserProfile } from "../../functions/firestoreService";

export default function EditAccountModal(props) {
  const [open, setOpen] = useState(false);
  const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);

  const currentUser = useSelector((state) => state.user.user);

  // console.log('Modal CurrentUser: ', currentUser)

  function handleClose() {
    props.pState(false);
    setOpen(false);
  }

  useEffect(() => {
    setOpen(props.openEditAccount);
    return () => {
        setOpen(true); // Cleanup state
      };
    // eslint-disable-next-line 
  }, []);

  const phoneRegExp =
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
      >
        {!isSubmitionCompleted && (
          <React.Fragment>
            <DialogTitle id='form-dialog-title' style={{ color: "navy" }}>Edit Account</DialogTitle>
            <DialogContent>
              <Formik
                initialValues={{
                  displayName: currentUser.displayName,
                  phoneNumber: currentUser.phoneNumber,
                }}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  //console.log("Values: ", values);
                  try {
                    updateUserProfile(values)
                    .then(handleClose());
                    setSubmitionCompleted(true);
                  } catch(error) {
                    window.alert(error);
                  }
                }}
                validationSchema={Yup.object().shape({
                    displayName: Yup.string("Enter your name")
                    .min(3, "Name should be of minimum 3 characters length")
                    .required("Required"),
                    phoneNumber: Yup.string("Enter your phone number")
                    .matches(phoneRegExp, "Phone number is not valid")
                    .required("Required"),
                })}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                  } = props;
                  return (
                    <form onSubmit={handleSubmit}>
                      <Grid container direction={"column"} spacing={0 }>
                        <Grid item>
                          <TextField
                            error={errors.displayName && touched.displayName}
                            label='displayName'
                            name='displayName'
                            type='displayName'
                            value={values.displayName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                              errors.displayName && touched.displayName && errors.displayName
                            }
                            margin='normal'
                            autoComplete='off'
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            error={errors.phoneNumber && touched.phoneNumber}
                            label='phoneNumber'
                            name='phoneNumber'
                            type='phoneNumber'
                            //className={classes.textField}
                            value={values.phoneNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                              errors.phoneNumber && touched.phoneNumber && errors.phoneNumber
                            }
                            margin='normal'
                            autoComplete='off'
                          />
                        </Grid>
                        <Grid item>
                          <DialogActions>
                            <Button
                              type='button'
                              disabled={isSubmitting}
                              onClick={handleClose}
                            >
                              Cancel
                            </Button>
                            <Button
                              type='button'
                              className='outline'
                              onClick={handleReset}
                              disabled={!dirty || isSubmitting}
                            >
                              Reset
                            </Button>
                            <Button type='submit' disabled={isSubmitting}>
                              Submit
                            </Button>
                          </DialogActions>
                        </Grid>
                      </Grid>
                    </form>
                  );
                }}
              </Formik>
            </DialogContent>
          </React.Fragment>
        )}
        {isSubmitionCompleted && (
          <React.Fragment>
            <DialogTitle id='form-dialog-title'>Thanks!</DialogTitle>
            <DialogContent>
              <DialogContentText>User Created!</DialogContentText>
              <DialogActions>
                <Button type='button' className='outline' onClick={handleClose}>
                  Back to app
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )}
      </Dialog>
    </React.Fragment>
  );
}
