import Typography from "@material-ui/core/Typography";
import { Redirect } from "react-router-dom";
import { List } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Container } from "@mui/material";

export default function AlertTimes() {
  const device = useSelector((state) => state.device.device);
  // console.log('DEVICE', device);
  // const temp = device.alert_history_temperature.temperature;
  // console.log('DEVICE TEMP', temp);
  // const temp2 = device.alert_history_temperature.temperature
  //   ? device.alert_history_temperature.temperature
  //   : null;
  // const time = device.alert_history_temperature.time
  //   ? device.alert_history_temperature.time
  //   : null;

  // console.log("device.alert_history_battery", device.alert_history_battery);

  let temp;
  let temp_time;
  
  let humid;
  let humid_time;

  let power;
  let power_time;

  let battery;
  let battery_time;

  let start_count;
  let start_time;
  let start_duration;

  let update_count;
  let update_time;
  let update_duration;

  let end_count;
  let end_time;
  let end_duration;

  // let end_count;
  // let end_time;

  // const updateTime = device ? Date.parse(device.dev_update_time) : null;
  // const startupTime = device ? Date.parse(device.dev_startup_time) : null;
  // const testTime = device.alert_history_battery.time ? console.log('XXXXXXXXXXXXXXXX') : null;
  // const testTime =
  //   device ? device.alert_history_battery !== null
  //     ? device.alert_history_battery.time.toDate()
  //     : null : null;

  //     testTime !== null ? console.log('testTime:', testTime) : console.log('null')
  // const udTime = new Date(updateTime);
  // // const udTime = new Date(updateTime);
  // const suTime = new Date(startupTime);
  // console.log('udTime', udTime);
  // console.log('suTime', suTime);
  // console.log('device.dev_update_time', device.dev_update_time);
  // device.alert_history_battery.time !== null
  //   ? console.log("device.alert_history_battery.time", testTime)
  //   : console.log("AlertTimes: no device");
  // console.log('device.alert_history_battery.time', testTime.toLocaleString());

  function toDateTime(secs) {
    const t = new Date(secs * 1000);
    // console.log('TTTTTTTTTTTTTTTT', t)
    return t.toLocaleString();
  }

  device
    ? (temp = device.alert_history_temperature.temperature)
    : (temp = null);
  device
    ? (temp_time = toDateTime(device.alert_history_temperature.time))
    : (temp_time = null);
  
    device
    ? (humid = device.alert_history_humidity.humidity)
    : (humid = null);
  device
    ? (humid_time = toDateTime(device.alert_history_humidity.time))
    : (humid_time = null);

  device ? (power = device.alert_history_power.power) : (power = null);
  device
    ? (power_time = toDateTime(device.alert_history_power.time))
    : (power_time = null);

  device ? (battery = device.alert_history_battery.battery) : (battery = null);
  battery !== null
    ? (battery_time = toDateTime(device.alert_history_battery.time))
    : (battery_time = null);

  device
    ? (start_count = device.alert_history_pir_motion_start.dev_pir_count)
    : (start_count = null);
  device
    ? (start_time = toDateTime(
        device.alert_history_pir_motion_start.dev_pir_time
      ))
    : (start_time = null);
  device
    ? (start_duration = device.alert_history_pir_motion_start.dev_pir_duration)
    : (start_duration = null);

  device
    ? (update_count = device.alert_history_pir_motion_update.dev_pir_count)
    : (update_count = null);
  device
    ? (update_time = toDateTime(
        device.alert_history_pir_motion_update.dev_pir_time
      ))
    : (update_time = null);
  device
    ? (update_duration =
        device.alert_history_pir_motion_update.dev_pir_duration)
    : (update_duration = null);

  device
    ? (end_count = device.alert_history_pir_motion_end.dev_pir_count)
    : (end_count = null);
  device
    ? (end_time = toDateTime(device.alert_history_pir_motion_end.dev_pir_time))
    : (end_time = null);
  device
    ? (end_duration = device.alert_history_pir_motion_end.dev_pir_duration)
    : (end_duration = null);

  toDateTime(battery_time);

  if (device) {
    return (
      <Container>
        <Typography
          style={{
            color: "navy",
          }}
          variant='h6'
          align='center'
        >
          Alert Times
        </Typography>
        <Typography
          component='span'
          variant='body2'
          style={{
            color: "navy",
          }}
        >
          <List>
            <b>{"Temperature Alert: "}</b>
            <br />
            {temp !== undefined ? `Temperature: ${temp}` : null}
            <br />
            {temp !== undefined ? `Time: ${temp_time.toLocaleString()}` : null}
          </List>
          <List>
            <b>{"Humidity Alert: "}</b>
            <br />
            {humid !== undefined ? `Humidity: ${humid}` : null}
            <br />
            {humid !== undefined ? `Time: ${humid_time.toLocaleString()}` : null}
          </List>
          <List>
            <b>{"Power Lost Alert: "}</b>
            <br />
            {power !== undefined ? `Power: ${power === 1 ? "true" : "false"}` : null}
            {/* {power !== undefined ? `Power: ${power}` : null} */}
            <br />
            {power !== undefined
              ? `Time: ${power_time.toLocaleString()}`
              : null}
          </List>
          <List>
            <b>{"Battery Low Alert: "}</b>
            <br />
            {battery !== undefined ? `Battery: ${battery}%` : null}
            <br />
            {battery !== undefined
              ? `Time: ${battery_time.toLocaleString()}`
              : // ? `Time: ${battery_time.toLocaleString('en-US' ,{ timeZone: 'Canada/East-Saskatchewan' })}`
                null}
          </List>
          <List>
            <b>{"PIR Motion Alert: "}</b>
            <br />
            {start_count !== undefined
              ? `Start Motion - Count: ${start_count}`
              : null}
            <br />
            {start_duration !== undefined
              ? `Longest Trigger Duration: ${start_duration} seconds`
              : null}
            <br />
            {start_time !== 'Invalid Date'
              ? `Start Time: ${start_time.toLocaleString()}`
              : null}
            <br />
            <br />
            {update_count !== undefined
              ? `Update Motion - Count: ${update_count}`
              : null}
            <br />
            {update_duration !== undefined
              ? `Longest Trigger Duration: ${update_duration} seconds`
              : null}
            <br />
            {update_time !== 'Invalid Date'
              ? `Update Time: ${update_time.toLocaleString()}`
              : null}
            <br />
            <br />
            {end_count !== undefined ? `End Motion - Count: ${end_count}` : null}
            <br />
            {end_duration !== undefined
              ? `Longest Trigger Duration: ${end_duration} seconds`
              : null}
            <br />
            {end_time !== 'Invalid Date'
              ? `End Time: ${end_time.toLocaleString()}`
              : null}
            {/* <br />
            <br />
            {end_count !== undefined ? `End Motion Count: ${end_count}` : null}
            <br />
            {end_time !== undefined ? `End Time: ${end_time}` : null} */}
          </List>
        </Typography>
      </Container>
    );
  } else {
    return <Redirect to='/userdevicepage' />;
  }
}
