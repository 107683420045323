import { createSlice } from "@reduxjs/toolkit";
//  import { createAsyncThunk } from "@reduxjs/toolkit";
// import { fetchUserDevicesFromFirestore } from "../functions/firestoreService";


// export const getUserDevices = createAsyncThunk(
//   "devices/getAllDevices",
//   async ( ) => {
//     try {
//       //console.log(email);
//       const response = await listenToDevices();
//       const response = await fetchUserDevicesFromFirestore();
//       //console.log('getalldevice response');
//       //console.log(typeof response);
//       //console.log(response);
//       return response;
//     } catch (err) {
//       //return rejectWithValue(err.message);
//       console.log('getalldeviceserror', err);
//       return err.message;
//     }
//   }
// );


export const userDeviceSlice = createSlice({
  name: "devices",
  initialState: {
    loading: "idle",
    devices: [],
    device: '',
    isDevice: false,
    error: null,
    initialized: false
  },
  reducers: {
    listenToDevices: (state, action) => {
      //console.log(...action.payload);
      state.devices = action.payload;
      state.initialized = true;
    },
    listenToOneDevice: (state, action) => {
      // console.log('AP: ', action.payload);
      state.device = action.payload;
      state.isDevice = true;
      //console.log('SD: ', ...state.device);
    }
  },
});

export const { listenToDevices, listenToOneDevice } = userDeviceSlice.actions;

export default userDeviceSlice.reducer;