import {
  Button,
  Container,
  Grid,
  Typography,
} from "@mui/material";

import { Box } from "@mui/system";
import { useSelector, useDispatch } from "react-redux";
import AddDeviceModal from "./forms/AddDeviceModal";
import { useHistory } from "react-router-dom";
import { listenToOneDevice } from "../store/userDeviceSlice";
import { useEffect, useState } from "react";
import { deleteDeviceInFirestore } from "../functions/firestoreService";
import { useConfirm } from "material-ui-confirm";
import EditDeviceModal from "./forms/EditDeviceModal";
import AlertTimes from "./devices/AlertTimes";
import AlertRecipients from "./devices/AlertRecipients";
import AlertSettings from "./devices/AlertSettings";
import CurrentState from "./devices/CurrentState";
import {
  getFunctions,
  httpsCallable,
  // connectFunctionsEmulator,
} from "@firebase/functions";
import fbApp from "../config/fb_config";

const functions = getFunctions(fbApp, "northamerica-northeast1");
// For local testing with emulator
// connectFunctionsEmulator(functions, "localhost", 5001)

const resetDevice = httpsCallable(functions, "resetDevice");

export default function UserDevicePage(prop) {
  const confirm = useConfirm();
  const loading = useSelector((state) => state.async.loading);
  const currentUser = useSelector((state) => state.user.user);
  const isUser = useSelector((state) => state.user.isUser);
  const userDevices = useSelector((state) => state.device.devices);
  const history = useHistory();
  const dispatch = useDispatch();
  const device = useSelector((state) => state.device.device);
  const [useDevice, setUseDevice] = useState(prop.device);
  const [openEditDevice, setOpenEditDevice] = useState(false);
  const [openAddDevice, setOpenAddDevice] = useState(false);

  //Function to pass to pass to AddDeviceModal in order to be able to open and close modal from modal
  const handleAddDevice = (a) => {
    setOpenAddDevice(a);
  };

  const handleEditDevice = (a) => {
    setOpenEditDevice(a);
  };

  const deleteDevice = () => {
    confirm({
      description: `Are you sure you want to delete ${useDevice._name}`,
    })
      .then(() => {
        deleteDeviceInFirestore(useDevice, currentUser);
        sessionStorage.clear();
        dispatch(listenToOneDevice(userDevices[0])); 
      })
      .catch(() => {
        // console.log("Delete Cancelled");
      });
  };

  const requestDeviceRestart = (deviceId, e) => {
    e.preventDefault();
    confirm({
      description: `Are you sure you want to restart ${useDevice._name} HeraldBox`,
    })
      .then(() => {
        resetDevice({ deviceId: deviceId })
          .then((result) => {
            // const data = result.data;
            // console.log("DATA: ", data);
          })
          .catch((error) => {
            alert("Error restarting", error);
          });
      })
      .catch(() => {
      });
  };

  useEffect(() => {
    setUseDevice(device);
  }, [device, dispatch]);

  if (!isUser) {
    return (
      <Container>
        {history.push("/")};
      </Container>
    );
  }

  if (loading && isUser) {
    return (
      <Container>
        <Box sx={{ my: 4 }}>
          <Typography
            style={{
              color: "navy",
            }}
            variant='h6'
            component='h1'
            gutterBottom
          >
            Device Page - Loading...
          </Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Container>
      {openAddDevice && (
        <AddDeviceModal
          openAddDevice={openAddDevice}
          pState={handleAddDevice}
        ></AddDeviceModal>
      )}
      {openEditDevice && (
        <EditDeviceModal
          openEditDeviceSettings={openEditDevice}
          pState={handleEditDevice}
        ></EditDeviceModal>
      )}
      <Box sx={{ my: 4 }}>
        <Typography
          style={{
            color: "navy",
          }}
          variant='h6'
          component='h1'
          gutterBottom
        >
          {useDevice && (
            <Grid container spacing={3}>
              &nbsp;&nbsp;&nbsp;
              {useDevice ? useDevice._name : null}
              &nbsp;&nbsp;&nbsp;
              <Button
                variant='outlined'
                style={{ color: "navy" }}
                size='small'
                onClick={() => setOpenEditDevice(true)}
              >
                Manage {useDevice._name}
              </Button>
              &nbsp;&nbsp;&nbsp;
              {device && (
                <Grid>
                  <Button
                    variant='outlined'
                    style={{ color: "navy" }}
                    size='small'
                    onClick={(e) => requestDeviceRestart(device._id, e)}
                  >
                    Restart {useDevice._name}
                  </Button>
                </Grid>
              )}
              &nbsp;&nbsp;&nbsp;
              {device && (
                <Grid>
                  <Button
                    variant='outlined'
                    style={
                      useDevice._is_demo
                        ? { color: "orange" }
                        : { color: "navy" }
                    }
                    size='small'
                    onClick={() => deleteDevice()}
                    disabled={useDevice._is_demo}
                  >
                    Delete {useDevice._name}
                  </Button>
                </Grid>
              )}
              {useDevice._is_demo && (
                <Grid>
                  <Typography
                    style={{
                      color: "orange",
                    }}
                    component='h1'
                    gutterBottom
                  >
                    Orange buttons are disabled in the Demo
                  </Typography>
                </Grid>
              )}
            </Grid>
          )}
        </Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid>
          {/* <Grid item xs={12} md={6} lg={2}> */}
          <CurrentState />
        </Grid>
        <Grid>
          {/* <Grid item xs={12} md={6} lg={2}> */}
          <AlertTimes />
        </Grid>
        <Grid>
          {/* <Grid item xs={12} md={6} lg={2}> */}
          <AlertSettings />
        </Grid>
        <Grid>
          {/* <Grid item xs={12} md={6} lg={2}> */}
          <AlertRecipients />
        </Grid>
      </Grid>
    </Container>
  );
}
