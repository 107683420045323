import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { updateDeviceInFirestore } from "../../functions/firestoreService";

export default function EditDeviceModal(props) {
  const [open, setOpen] = useState(false);
  const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
  const device = useSelector((state) => state.device.device);
  // console.log("editdevicemodal", device);

  // Rename form keys for submission to Firestore
  function renameKey(obj, oldKey, newKey) {
    obj[newKey] = obj[oldKey];
    delete obj[oldKey];
  }

  function handleClose() {
    props.pState(false);
    setOpen(false);
  }

  useEffect(() => {
    setOpen(props.openEditDeviceSettings);
    return () => {
      setOpen({}); // Cleanup state
    };
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
      >
        {!isSubmitionCompleted && (
          <React.Fragment>
            <DialogTitle id='form-dialog-title' style={{ color: "navy" }}>
              Manage Device
            </DialogTitle>
            <DialogContent>
              <Formik
                initialValues={{
                  _id: device._id,
                  deviceName: device._name,
                }}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  renameKey(values, "deviceName", "_name");
                  try {
                    updateDeviceInFirestore(values).then(handleClose());
                    setSubmitionCompleted(true);
                  } catch (error) {
                    window.alert(error);
                  }
                }}
                validationSchema={Yup.object().shape({
                  deviceName: Yup.string("Edit device name").min(
                    3,
                    "Device name should be of minimum 3 characters length"
                  ),
                })}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                  } = props;
                  return (
                    <form onSubmit={handleSubmit}>
                      <Grid container direction={"column"} spacing={0}>
                        <Grid item>
                          <TextField
                            error={errors.deviceName && touched.deviceName}
                            label='deviceName'
                            name='deviceName'
                            type='deviceName'
                            value={values.deviceName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                              errors.deviceName &&
                              touched.deviceName &&
                              errors.deviceName
                            }
                            margin='normal'
                            autoComplete='off'
                          />
                        </Grid>
                        <Grid item>
                          <DialogActions>
                            <Button
                              type='button'
                              disabled={isSubmitting}
                              onClick={handleClose}
                            >
                              Cancel
                            </Button>
                            <Button
                              type='button'
                              className='outline'
                              onClick={handleReset}
                              disabled={!dirty || isSubmitting}
                            >
                              Reset
                            </Button>
                            <Button type='submit' disabled={isSubmitting}>
                              Submit
                            </Button>
                          </DialogActions>
                        </Grid>
                      </Grid>
                    </form>
                  );
                }}
              </Formik>
            </DialogContent>
          </React.Fragment>
        )}
        {isSubmitionCompleted && (
          <React.Fragment>
            <DialogTitle id='form-dialog-title'>Thanks!</DialogTitle>
            <DialogContent>
              <DialogContentText>Device Updated!</DialogContentText>
              <DialogActions>
                <Button type='button' className='outline' onClick={handleClose}>
                  Back to app
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )}
      </Dialog>
    </React.Fragment>
  );
}
