import { setUserFireStoreData } from "./firestoreService";
import {
  reauthenticateWithCredential,
  EmailAuthProvider,
  updatePassword,
  createUserWithEmailAndPassword,
  updateProfile,
  signInWithEmailAndPassword,
  signOut,
  sendEmailVerification,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth } from "../config/fb_config";

//create account with email verification
export async function createNewUserWithEmailAndPassword(creds) {
  //console.log("Test Register creds", creds.phone);
  createUserWithEmailAndPassword(auth, creds.email, creds.password)
    .then((userCredential) => {
      const user = userCredential.user;
      updateProfile(user, {
        displayName: creds.name,
      })
        .then(() => {
          setUserFireStoreData(user, creds.phone);
        })
        .then(() => {
          sendEmailVerification(auth.currentUser).then((response) => {
            // console.log("Email verification sent");
            // console.log("Email verification sent from function", response);
          });
        })
        .catch((error) => {
          console.log("Create Error: ", error);
          alert(error.message);
        });
    })
    .catch((error) => {
      alert(error.message);
      // throw error;
    });
}

// **Create new user in Firebase and in Firestore
export async function registerInFirebase(creds) {
  // console.log("Register creds", creds.phone);
  createUserWithEmailAndPassword(auth, creds.email, creds.password)
    .then((userCredential) => {
      const user = userCredential.user;
      updateProfile(user, {
        displayName: creds.name,
      })
        .then(() => {
          setUserFireStoreData(user, creds.phone);
        })
        .catch((error) => {
          console.log(error);
        });
    })
    .catch((error) => {
      throw error;
    });
}

//**Sign in with email and password
export async function signInWithEmail(creds) {
  signInWithEmailAndPassword(auth, creds.email, creds.password)
    .then((userCredential) => {
      // var user = userCredential.user;
      // console.log("User Signed In: ", user);
    })
    .catch((error) => {
      console.log(error.message);
      alert(error.code);
    });
}

//**Signout out of firebase
export async function signOutFirebase() {
  signOut(auth)
    .then(() => {
      // console.log("Sign-out successful.");
    })
    .catch((error) => {
      // console.log(error);
    });
}

export async function updateUserFireBaseProfile(creds) {
  updateProfile(auth.currentUser, {
    displayName: creds.displayName,
  })
    .then(() => {
      // console.log("updateUserprofile completed");
    })
    .catch((error) => {
      console.log("updateUserprofile error", error);
    });
}

//**Reauthenticate and update password
export async function updateUserPassword(creds) {
  const user = auth.currentUser;
  const credential = EmailAuthProvider.credential(
    user.email,
    creds.currentPassword
  );
  reauthenticateWithCredential(user, credential)
    .then(() => {
      updatePassword(user, creds.password)
        .then(() => {
          alert("Password Updated");
        })
        .catch((error) => {
          console.log("Update password Error:", error);
        });
      console.log("Reauthenticated");
    })
    .catch((error) => {
      alert(error);
      console.log("Reauthenticate Error:", error);
    });
}

export async function setPasswordEmail(creds) {
  sendPasswordResetEmail(auth, creds.email)
    .then(() => {
      // console.log("Password reset email sent!", creds.email);
      // console.log(auth);
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
    });
  // ..
}
