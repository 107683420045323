import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { asyncStart, asyncFinish, asyncError } from "../store/asyncSlice";
import { dataFromSnapshot } from "./firestoreService";
import { onSnapshot } from "@firebase/firestore";

export default function useFirestoreDoc({query, data, deps, shouldExecute = true}) {
    const dispatch = useDispatch();

    useEffect(() => {
        if (!shouldExecute) return;
        dispatch(asyncStart());
        const unsubscribe = onSnapshot(query(),
            snapshot => {
                if (!snapshot.exists) {
                    dispatch(asyncError({code: 'not-found', message: 'Could not find document'}));
                    return;
                }
                data(dataFromSnapshot(snapshot));
                dispatch(asyncFinish());
            },
            error => dispatch(asyncError())
        );
        return () => {
            unsubscribe()
        }
    }, deps) // eslint-disable-line react-hooks/exhaustive-deps
}