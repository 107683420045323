import { BrowserRouter as Router , Switch, Route } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ConfirmProvider } from 'material-ui-confirm';
import Navbar from './components/Navbar';
import Home from './components/Home';
import UserAccountPage from './components/UserAccountPage';
import UserDevicePage from './components/UserDevicePage';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { verifyAuth } from './store/authSlice';
import useFirestoreDoc from './functions/useFirestoreDoc';
import { listenToUserDevicesFromFirestore, listenToUserProfileFromFirestore } from './functions/firestoreService';
import { listenToAuthUser } from './store/authUserSlice';
import useFirestoreCollection from './functions/useFirestoreCollection';
import { listenToDevices } from './store/userDeviceSlice';
import NotFound from './components/NotFound';
import DeviceNavbar from './components/DeviceNavbar';


const theme = createTheme({
  palette: {
    primary: {
      main: '#0d47a1',
    },    
  },
})

function App() {
   const dispatch = useDispatch();
   const uid = useSelector((state) => state.auth.currentUser.uid);
   let isId;

   useEffect(() => {
    dispatch(verifyAuth());
  }, [dispatch]);

  if(uid === null){
    isId = false;
  } else {
    isId = true;
  }

   useFirestoreDoc({
    query: () => listenToUserProfileFromFirestore(uid),
    data: (user) => dispatch(listenToAuthUser(user)),
    deps: [dispatch, uid],
    shouldExecute: isId,
  });

  useFirestoreCollection({
    query: () => listenToUserDevicesFromFirestore(uid),
    data: (devices) => dispatch(listenToDevices(devices)),
    deps: [dispatch, uid],
  });

  // const testx = listenToUserDevicesFromFirestore(uid)
  // console.log('TTTEESTXXXXX', testx)

  return (
    <>
    <ThemeProvider theme={theme}>
      <ConfirmProvider>
      <Router>
        <Navbar />
          <Switch>
          <Route exact path="/" component={Home}/>
          <Route exact path="/useraccountpage" component={UserAccountPage}/>
          <Route exact path="/userdevicepage" component={UserDevicePage}/>
          <Route exact path="/devicenavbar" component={DeviceNavbar}/>
          <Route component={NotFound} />
          </Switch>
      </Router>
      </ConfirmProvider>
    </ThemeProvider>
    </>
  );
}

export default App;
