import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { addDeviceMessageUser } from "../../functions/firestoreService";

export default function ManageRecipientsModal(props) {
  const [open, setOpen] = useState(false);
  const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
  const device = useSelector((state) => state.device.device);
  //   console.log("DEVICE:", device);

  const nameList = [];
  device.set_message_users.map((user) => nameList.push(user.name));
  // console.log('namelist2', nameList)

  function handleClose() {
    props.pState(false);
    setOpen(false);
  }

  useEffect(() => {
    setOpen(props.openManageRecipients);
    return () => {
      setOpen({}); // Cleanup state
    };
    // eslint-disable-next-line
  }, []);

  function checkName(name) {
    device.set_message_users.map((user) => {
      // console.log(user);
      if (user.name === name) {
        //   console.log('checkname true')
        return true;
      } else {
        // console.log('checkname false')
        return false;
      }
    });
  }

  const phoneRegExp =
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
      >
        {!isSubmitionCompleted && (
          <React.Fragment>
            <DialogTitle id='form-dialog-title' style={{ color: "navy" }}>
              Add Recipient
            </DialogTitle>
            <DialogContent>
              <Formik
                initialValues={{
                  name: "",
                  phone: "",
                  email: "",
                  _id: device._id,
                }}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  // 'if' logic should be moved to firestore function
                  //   if (
                  //     device.set_message_users.length <
                  //       device._max_message_users ||
                  //     !checkName(values.name)
                  //   ) {
                  // checkName(values.name)
                  try {
                    checkName(values.name);
                    addDeviceMessageUser(values)
                      .then(handleClose())
                      .catch((error) => {
                        // console.log(error);
                      });
                    // console.log("FORM: ", values);
                    setSubmitionCompleted(true);
                  } catch (error) {
                    window.alert(error);
                  }
                  //   } else {
                  //     alert(
                  //       "Maximum Message Users is Four! Can not use a name that already exits"
                  //     );
                  //     handleClose();
                  //   }
                }}
                validationSchema={Yup.object().shape({
                  name: Yup.string("Enter your name")
                    .min(3, "Name should be of minimum 3 characters length")
                    .notOneOf(nameList, "User already exists")
                    .required("Required"),
                  phone: Yup.string("Enter your phone number")
                    .matches(phoneRegExp, "Phone number is not valid")
                    .required("Required"),
                  email: Yup.string("Enter your email")
                    .email("Invalid email format")
                    .required("Required"),
                })}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                  } = props;
                  return (
                    <form onSubmit={handleSubmit}>
                      <Grid container direction={"column"} spacing={0}>
                        <Grid item>
                          <TextField
                            error={errors.name && touched.name}
                            label='name'
                            name='name'
                            type='name'
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                              errors.name && touched.name && errors.name
                            }
                            margin='normal'
                            autoComplete='off'
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            error={errors.phone && touched.phone}
                            label='phone'
                            name='phone'
                            type='phone'
                            //className={classes.textField}
                            value={values.phone}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                              errors.phone && touched.phone && errors.phone
                            }
                            margin='normal'
                            autoComplete='off'
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            error={errors.email && touched.email}
                            label='email'
                            name='email'
                            tpye='email'
                            //className={classes.textField}
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                              errors.email && touched.email && errors.email
                            }
                            margin='normal'
                            autoComplete='off'
                          />
                        </Grid>
                        <Grid item>
                          <DialogActions>
                            <Button
                              type='button'
                              disabled={isSubmitting}
                              onClick={handleClose}
                            >
                              Cancel
                            </Button>
                            <Button
                              type='button'
                              className='outline'
                              onClick={handleReset}
                              disabled={!dirty || isSubmitting}
                            >
                              Reset
                            </Button>
                            <Button type='submit' disabled={isSubmitting}>
                              Submit
                            </Button>
                          </DialogActions>
                        </Grid>
                      </Grid>
                    </form>
                  );
                }}
              </Formik>
            </DialogContent>
          </React.Fragment>
        )}
        {isSubmitionCompleted && (
          <React.Fragment>
            <DialogTitle id='form-dialog-title'>Thanks!</DialogTitle>
            <DialogContent>
              <DialogContentText>User Created!</DialogContentText>
              <DialogActions>
                <Button type='button' className='outline' onClick={handleClose}>
                  Back to app
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )}
      </Dialog>
    </React.Fragment>
  );
}
