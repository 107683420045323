import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { addDeviceToFirestore } from "../../functions/firestoreService";

export default function AddDeviceModal(props) {
  const [open, setOpen] = useState(false);
  const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
  const uid = useSelector((state) => state.auth.currentUser.uid);

  function handleClose() {
    props.pState(false);
    setOpen(false);
  }

  useEffect(() => {
    setOpen(props.openAddDevice);
    return () => {
      setOpen({}); // State cleanup
    };
    // eslint-disable-next-line
  }, []);

  console.log('PROPS FROM ADD', props)

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
      >
        {!isSubmitionCompleted && (
          <React.Fragment>
            <DialogTitle id='form-dialog-title' style={{ color: "navy" }}>
              Add Device
            </DialogTitle>
            <DialogContent>
              <DialogContentText color='navy'>
                Create a new device
              </DialogContentText>
              <Formik
                initialValues={{
                  deviceId: "",
                  name: ""
                }}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  values.owner = uid;
                  try {
                    addDeviceToFirestore(values);
                    handleClose();
                    setSubmitionCompleted(true);
                  } catch (error) {
                    window.alert(error);
                  }
                }}
                validationSchema={Yup.object().shape({
                  deviceId: Yup.string("Enter the device ID")
                    .required("Required"),
                  name: Yup.string("Enter device name")
                    .min(3, "Device name should be of minimum 3 characters length")
                    .required("Required")
                })}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                  } = props;
                  return (
                    <form onSubmit={handleSubmit}>
                      <Grid container direction={"column"} spacing={0}>
                        <Grid item>
                          <TextField
                            error={errors.name && touched.name}
                            label='name'
                            name='name'
                            type='name'
                            /* className={classes.textField} */
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                              errors.name && touched.name && errors.name
                            }
                            margin='normal'
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            error={errors.deviceId && touched.deviceId}
                            label='deviceId'
                            name='deviceId'
                            tpye='deviceId'
                            value={values.deviceId}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                              errors.deviceId && touched.deviceId && errors.deviceId
                            }
                            margin='normal'
                          />
                        </Grid>
                        <Grid item>
                          <DialogActions>
                            <Button
                              type='button'
                              disabled={isSubmitting}
                              onClick={handleClose}
                            >
                              Cancel
                            </Button>
                            <Button
                              type='button'
                              className='outline'
                              onClick={handleReset}
                              disabled={!dirty || isSubmitting}
                            >
                              Reset
                            </Button>
                            <Button type='submit' disabled={isSubmitting}>
                              Submit
                            </Button>
                          </DialogActions>
                        </Grid>
                      </Grid>
                    </form>
                  );
                }}
              </Formik>
            </DialogContent>
          </React.Fragment>
        )}
        {/* {isSubmitionCompleted && (
          <React.Fragment>
            <DialogTitle id='form-dialog-title'>Thanks!</DialogTitle>
            <DialogContent>
              <DialogContentText>Device Created!</DialogContentText>
              <DialogActions>
                <Button type='button' className='outline' onClick={handleClose}>
                  Back to app
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )} */}
      </Dialog>
    </React.Fragment>
  );
}